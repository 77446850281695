@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Sacramento&family=Bodoni+Moda:opsz@6..96&family=Arimo&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.App {
  text-align: center;
  background-color: #f5f5f5;
}

button {
  border: 1px solid #dccfec;
  color: black;
  padding: 10px 15px;
  border-radius: 5px;
  font-variant: small-caps;
  font-size: 15px;
}

/* banner */
.banner {
  background-image:     
  linear-gradient(
    rgba(220,207,236, 0.45), 
    rgb(220,207,236, 0.5)
  ),
   url('https://images.unsplash.com/photo-1494390248081-4e521a5940db?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1595&q=80');
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 2px 4px #f3f3f3;
}

/* Main */
.main {
  font-family: 'Arimo', sans-serif;
  font-size: 14px;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-template-areas: "content about";
  /* grid-auto-rows: minmax(450px, auto); */
  padding: 50px;
  grid-gap: 100px;
  width: 80%;
  margin: auto;
}

.main div {
  background-color: white;
}

/* about page ------------------------------------*/
#about-page {
  width: 80%;
  margin: auto;
  padding: 20px;
  .text {
    padding: 10px;
  }
}


/* blog page -------------------------------------*/
#blog-posts {
  width: 80%;
  margin: auto;
}


/* recipes list page ------------------------------*/
.content {
  padding: 20px;
}

#recipes-page {
  width: 80%;
  margin: auto;
  padding: 20px;
}

/* recipe page ----------------------------------- */
.recipe-container {
  width: 90%;
  border: 1px solid #dccfec;
  margin: auto;
  display: grid;
  padding: 10px;

  #continue {
    font-size: 15px;
    font-variant-caps: all-small-caps;
    width: 30%;
    background-color: #dccfec;
    margin: auto;
  }
}

.recipe-header {
  font-variant-caps: all-small-caps;
  font-size: 35px;
}

.recipe-tags {
  font-size: 12px;
  padding: 4px;
}

.recipe-description {
  font-size: 14px;
  width: 80%;
  margin: auto;
  padding: 6px;
}

.recipe-photo {
  #recipe-photo {
    width: 70%;
    object-fit: cover;
    padding: 10px;
  }
}

.recipe-footer {
  display: flex;
  padding: 20px;
  border: 1px solid orange;
}

/* copyright ------------------------------------- */
.copyright {
  background-color: white;
  font-variant: small-caps;
  height: 50px;
  color: #aca599;
  padding-top: 20px;
}